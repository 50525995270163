<template>
  <v-card>
    <v-card-text>
      <v-treeview :items="items" dense :search="search">
        <template v-slot:prepend="{ item, open }">
          <v-icon v-if="!item.name.endsWith('.pdf')">
            {{ open ? 'mdi-folder-open' : 'mdi-folder' }}
          </v-icon>
          <v-icon v-else>mdi-file</v-icon>
        </template>

        <template v-slot:label="{ item }">
          <span v-if="item.name.endsWith('.pdf')"
            >{{ item.name }}
            <ReportButton
              color="primary"
              resource="report/portfolioarchive"
              :parameters="{
                schoolClass: item.schoolClass,
                student: item.student,
                portfolio: item.name,
              }"
            />
          </span>
          <span v-else>{{ item.name }}</span>
        </template>
      </v-treeview>
    </v-card-text>
  </v-card>
</template>

<script>
import ReportButton from '@/components/ReportButton.vue';
export default {
  name: 'PortfolioArchive',
  props: ['search'],
  components: { ReportButton },
  data: () => ({
    items: [],
    loading: false,
  }),
  computed: {
    filter() {
      return (item, search, textKey) => item[textKey].indexOf(search) > -1;
    },
  },
  methods: {
    async getData() {
      this.loading = true;
      this.items = await this.apiList({ resource: 'portfolio/archive' });
      this.loading = false;
    },
  },
  created() {
    this.getData();
  },
};
</script>
