<template>
  <v-btn
    @click="print"
    :class="classes"
    v-bind="$attrs"
    v-on="{ ...$listeners }"
    :icon="icon"
  >
    <v-progress-circular
      :rotate="360"
      :size="30"
      :width="5"
      :value="progress"
      v-if="generating"
    ></v-progress-circular>
    <v-icon v-else :left="!icon">mdi-download</v-icon><slot />
  </v-btn>
</template>
<script>
export default {
  name: 'ReportButton',
  props: {
    parameters: { type: Object, default: () => {} },
    resource: { type: String },
    title: { type: String },
    icon: { type: Boolean },
  },
  data() {
    return {
      generating: false,
      progress: 0,
    };
  },
  computed: {
    classes() {
      const result = ['btn', 'btn-default'];
      if (this.generating) result.push('disabled');
      return result;
    },
  },
  methods: {
    download(fileInfo) {
      this.generating = false;
      fileInfo.download();
    },
    error() {
      this.$root.showError('Fehler beim Erzeugen des Reports!');
      this.generating = false;
    },
    async print() {
      if (this.generating) return;
      this.progress = 0;
      const parameters = this.parameters;
      if (parameters.inputTitle) {
        parameters.input = prompt(parameters.inputTitle, '0');
        if (!parameters.input) return;
      }
      this.generating = true;
      const params = {
        resource: this.resource,
        data: parameters,
        updateProgress: this.updateProgress,
        download: this.download,
        error: this.error,
      };
      if (!(await this.apiReport(params))) {
        this.generating = false;
      }
    },
    updateProgress(progress) {
      this.progress = progress;
    },
  },
};
</script>
